import { FC, useCallback, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import {
  auth,
  editUserInfo,
  registerUser,
} from "../../../../services/firebase/auth/auth";
import {
  assertNotEmpty,
  isObjEmpty,
  TEXT_ERRORS,
  validateEmail,
  validatePassword,
} from "../../../../utils/validators";
import { Button } from "../../../core/Button";
import { Col, Container, Row } from "../../../core/Container";
import { Spinner } from "../../../core/Loading";
import { authActions } from "../../../../services/redux/reducers/userAuth/user";
import { useUpdateProfile } from "react-firebase-hooks/auth";
import { images } from "../../../../assets/images/images";
import { GTMEvents } from "../../../../utils/constants/googleTagManager";
import { mixpanelSignup } from "../../../../utils/tracker";
import { sendNewSignUpsToZapierHook } from "../../../../services/tracking/activeCampaign";
import "react-phone-number-input/style.css";

const CheckoutSignup: FC<{
  onSuccess?: () => any;
  onLoginPressed: () => any;
}> = ({ onSuccess, onLoginPressed }) => {
  const [isSignupLoading, setIsSignupLoading] = useState<boolean>(false);
  const [errorForm, setErrorForm] = useState<string | null>();
  const [valuePhone, setValuePhone] = useState<string>();
  const [updateProfile] = useUpdateProfile(auth);

  const [isHidden, setIsHidden] = useState<boolean>(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validate = (values: any) => {
    const errors: any = {};
    const isFr = i18next.language == "fr";

    if (!values.firstname) {
      errors.firstname = t("required", {
        item: isFr ? "Le nom complet est" : "The first name is",
      });
    } else {
      if (values.firstname.length < 2) {
        errors.firstname = t("invalid", {
          item: isFr ? "un nom" : "first name",
        });
      }
    }
    if (!values.lastname) {
      errors.lastname = t("required", {
        item: isFr ? "Le nom complet est" : "The last name is",
      });
    } else {
      if (values.lastname.length < 2) {
        errors.lastname = t("invalid", { item: isFr ? "un nom" : "last name" });
      }
    }
    if (!values.email) {
      errors.email = t("required", {
        item: isFr ? "Une adresse email est" : "The email is",
      });
    } else {
      if (!validateEmail(values.email)) {
        errors.email = t("invalid", {
          item: isFr ? "une adresse email" : "email",
        });
      }
    }
    if (!values.password) {
      errors.password = t("required", {
        item: isFr ? "Mot de passe" : "The password is",
      });
    } else {
      if (!validatePassword(values.password, { minLength: 8 })) {
        errors.password = t("strongPassword");
      }
    }
    if (!values.terms) {
      errors.terms = t("required", {
        item: isFr
          ? "Vous devez accepter les CGV"
          : "You must accept the terms and conditions",
      });
    }
    return errors;
  };
  const saveSignUpInActiveCampaign = useCallback(
    (email: string, firstName: string, lastName: string, phone: string) => {
      const localStorage = window.localStorage;
      const tag = localStorage.getItem("tag");
      const slug = localStorage.getItem("slug");
      sendNewSignUpsToZapierHook({
        email,
        firstName,
        lastName,
        phone,
        tag,
        slug,
      });
    },
    []
  );
  const onSignUp = async (values: any) => {
    setErrorForm(null);
    setIsSignupLoading(true);
    try {
      if (!isObjEmpty(errors) || !valuePhone) throw Error(TEXT_ERRORS.REQUIRED);
      const valuesUpdate = {
        email: values.email,
        password: values.password,
        fullname: values.firstname + " " + values.lastname,
      };
      assertNotEmpty(valuesUpdate);
      const { user } = await registerUser(valuesUpdate);
      await editUserInfo(values.firstname + " " + values.lastname, "fr");
      mixpanelSignup(user);

      dispatch(
        authActions.setUserCustomData({
          customData: {
            DisplayName: values.firstname + " " + values.lastname,
            Country: "AE",
            DefaultLanguage: "En",
          },
        })
      );

      onSuccessfulSignup();
    } catch (error: any) {
      setErrorForm(error.message);
    } finally {
      setIsSignupLoading(false);
    }
    saveSignUpInActiveCampaign(
      values.email,
      values.firstname,
      values.lastname,
      valuePhone!
    );
    console.log(valuePhone);
    await updateProfile({
      displayName: values.firstname + " " + values.lastname,
    });
  };

  const onSuccessfulSignup = useCallback(async () => {
    setErrorForm(null);
    try {
      setIsSignupLoading(true);

      window.dataLayer.push({
        event: GTMEvents.successfulSignup,
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      setErrorForm(error.message);
    } finally {
      setIsSignupLoading(false);
    }
  }, [onSuccess]);

  const { handleChange, handleBlur, values, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        terms: false,
      },
      validate,
      onSubmit: onSignUp,
    });
  const inputClass = (
    field: "firstname" | "lastname" | "password" | "email" | "terms"
  ) =>
    `w-full h-[55px] border-2 rounded-md ps-4 ${
      touched[field] && errors[field] ? "border-red-500" : "border-[#E0DFE2]"
    }`;
  const disabledBtn = isSignupLoading;

  return (
    <Container className="mt-10">
      {isSignupLoading ? (
        <div className="mt-40">
          <Spinner />
        </div>
      ) : (
        <Col className="p-[8px] sm:p-[20px] w-full bg-white rounded-md gap-6">
          <form onSubmit={handleSubmit}>
            <h3 className="font-bold text-3xl">{t("createAcc")}</h3>
            <Col className="w-full gap-2 mt-2 aifs">
              <h5 className="font-bold">{t("fullname")}</h5>
              <Row className="gap-4 w-full">
                <Col className="flex-1">
                  <input
                    placeholder={t("firstname")}
                    name="firstname"
                    type="name"
                    value={values.firstname}
                    className={inputClass("firstname")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.firstname && errors.firstname && (
                    <span className="text-red-500 text-sm text-center self-start">
                      {errors.firstname}
                    </span>
                  )}
                </Col>
                <Col className="flex-1">
                  <input
                    placeholder={t("lastname")}
                    name="lastname"
                    type="name"
                    value={values.lastname}
                    className={inputClass("firstname")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.lastname && errors.lastname && (
                    <span className="text-red-500 text-sm text-center self-start">
                      {errors.lastname}
                    </span>
                  )}
                </Col>
              </Row>

              <h5 className="font-bold">{t("mobile")}</h5>
              <div
                className="w-full h-[55px] rounded-md ps-4"
                style={{ border: "solid #E0DFE2", borderWidth: "2px" }}
              >
                <PhoneInput
                  defaultCountry="AE"
                  value={valuePhone}
                  className="w-[96%] h-full"
                  countrySelectProps={{
                    arrowComponent: () => {
                      return <img alt="" src={images.ArrowIcon} width={10} />;
                    },
                  }}
                  placeholder="+1 234 5678"
                  onChange={setValuePhone}
                />
              </div>

              {(valuePhone ?? "") !== "" ? (
                !isValidPhoneNumber(valuePhone ?? "") && (
                  <span className="text-red-500 text-sm text-center">
                    {t("invalidNumber")}
                  </span>
                )
              ) : (
                <></>
              )}

              <h5 className="font-bold">{t("email")}</h5>
              <input
                placeholder={t("email")}
                name="email"
                type="email"
                value={values.email}
                className={inputClass("firstname")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email && (
                <span className="text-red-500 text-sm text-center">
                  {errors.email}
                </span>
              )}

              <h5 className="font-bold">{t<any>("password")}</h5>
              <Row className="relative w-full items-center justify-center">
                <input
                  placeholder={t("password")}
                  value={values.password}
                  name="password"
                  type={isHidden ? "password" : "text"}
                  className="w-full h-[55px] border-2 border-[#E0DFE2] rounded-md ps-4 pe-14"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span
                  className="absolute z-20 right-6 cursor-pointer"
                  onClick={() => setIsHidden((st) => !st)}
                >
                  {isHidden ? (
                    <EyeSlashIcon className="w-5 h-auto fill-black opacity-50" />
                  ) : (
                    <EyeIcon className="w-5 h-auto fill-black" />
                  )}
                </span>
              </Row>
              {touched.password && errors.password && (
                <span className="text-red-500 text-sm text-center">
                  {errors.password}
                </span>
              )}
              <div className="relative w-full">
                <label className="flex items-center my-4">
                  <input
                    type="checkbox"
                    name="terms"
                    className={`mr-2 ${
                      touched["terms"] && errors["terms"]
                        ? "border border-red-500"
                        : "border border-[#E0DFE2]"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span
                    className={`text-xs ${
                      touched["terms"] && errors["terms"]
                        ? "text-red-500"
                        : "text-[#5D5C61]"
                    }`}
                  >
                    J’ai lu et j’accepte les{" "}
                    <a href="#" target="_blank">
                      Conditions Générales de Vente
                    </a>{" "}
                    et la{" "}
                    <a href="#" target="_blank">
                      Politique de confidentialité
                    </a>
                    .
                  </span>
                </label>
              </div>
            </Col>

            <Button
              className="w-full h-[50px] rounded-md green-gradient-button-bg text-white font-medium"
              type="submit"
              disabled={disabledBtn}
            >
              {t<any>("signup")}
            </Button>
            {errorForm && (
              <span className="text-red-500 text-sm self-center">
                {t<any>(errorForm)}
              </span>
            )}

            <h5 className="text-s text-[#5D5C61] font-medium text-center self-center mt-4">
              {t("haveAcc")}{" "}
              <span
                onClick={onLoginPressed}
                className="text-[#71BD22] cursor-pointer underline"
              >
                {t("singin")}
              </span>
            </h5>
          </form>
        </Col>
      )}
    </Container>
  );
};

export default CheckoutSignup;
