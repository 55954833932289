import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import clsx from "clsx";

import {
  applyCoupon,
  createSubscription,
  getBillingInfo,
  getPaymentIntent,
} from "../../../../services/apis/checkout/plans";
import { Col, Row } from "../../../core/Container";
import { Spinner } from "../../../core/Loading";
import { MODE_DEBUG } from "../../../../utils/constants/config";
import { auth } from "../../../../services/firebase/auth/auth";
import FailedLottie from "../../../../assets/animations/paymentFailed.json";
import SuccessLottie from "../../../../assets/animations/paymentSuccess.json";
import { mixpanelCheckout } from "../../../../utils/tracker";
import { GTMEvents } from "../../../../utils/constants/googleTagManager";
import { PlanTypes, PriceTypes } from "../../../../types/plan.types";
import { couponType } from "../../../../utils/constants/plans";

import { CountrySelector } from "./CountrySelector";

const Form: FC<{
  plan?: PlanTypes;
  setPrice: any;
  price: PriceTypes;
  coupon?: couponType;
  setCoupon: any;
}> = ({ plan, setPrice, price, coupon, setCoupon }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>(t("somethingWrong"));
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [zip, setZip] = useState<string>();
  const [city, setCity] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [user, authLoading] = useAuthState(auth);

  const [showBillingInfo, setShowBillingInfo] = useState<boolean>(true);
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(false);

  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    // sending "checkout" event to GTM as soon as we load the strip form
    window.dataLayer.push({
      event: GTMEvents.checkout,
    });
    if (price.currency) {
      getBillingInfo(price.currency).then(({ data }) => {
        setFirstName(data.user.firstname);
        setLastName(data.user.lastname);
        setCompany(data.company);
        setAddress(data.line1);
        setZip(data.postale_code);
        setCity(data.city);
        setCountry(data.country);
      });
    }
  }, [price.currency]);

  const handlePaymentThatRequiresCustomerAction = useCallback(
    async ({ subscription, invoice, priceId, paymentMethodId }: any) => {
      if (subscription && subscription.status === "active") {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent
        : getPaymentIntent(subscription);

      if (
        paymentIntent &&
        (paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation")
      ) {
        return stripe
          ?.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
            setup_future_usage: "off_session",
          })
          .then((result: any) => {
            if (result.error) {
              // start code flow to handle updating the payment details
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              throw result;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                // There's a risk of the customer closing the window before callback
                // execution. To handle this case, set up a webhook endpoint and
                // listen to invoice.payment_succeeded. This webhook endpoint
                // returns an Invoice.
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          });
      } else {
        // No customer action needed
        return { subscription, priceId, paymentMethodId };
      }
    },
    [stripe]
  );

  const handleRequiresPaymentMethod = useCallback(
    ({ subscription, paymentMethodId, priceId, invoice }: any) => {
      if ((subscription && subscription.status === "active") || invoice) {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription &&
        subscription.latest_invoice.payment_intent.status ===
          "requires_payment_method"
      ) {
        throw new Error("Your card was declined.");
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },
    []
  );

  const onSubscriptionComplete = useCallback(
    async (result: any) => {
      // Payment was successful. Provision access to your service.
      if (result) {
        // sending "purchase" event to GTM after successful payment
        window.dataLayer.push({
          event: GTMEvents.purchase,
          price: price.amount / 100,
          currency: "EUR",
        });
        setPaymentSuccess(true);

        if (user && !authLoading) {
          const token = await user.getIdToken();
          mixpanelCheckout(user.uid, "success", token, price.stripe_id);
        }
      }
    },
    [authLoading, price, user]
  );

  const onPaymentStart = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    if (firstName != null && firstName?.trim() === "") {
      setShowBillingInfo(true);
      setFirstName("");
      return;
    }
    if (lastName != null && lastName?.trim() === "") {
      setShowBillingInfo(true);
      setLastName("");
      return;
    }
    if (city != null && city?.trim() === "") {
      setShowBillingInfo(true);
      setCity("");
      return;
    }
    if (zip != null && zip?.trim() === "") {
      setShowBillingInfo(true);
      setZip("");
      return;
    }
    if (address != null && address?.trim() === "") {
      setShowBillingInfo(true);
      setAddress("");
      return;
    }
    if (company != null && company?.trim() === "") {
      setShowBillingInfo(true);
      setCompany("");
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }
    setLoading(true);

    await stripe
      ?.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: (firstName ?? "") + " " + (lastName ?? ""),
            address: {
              city: city,
              country: (typeof country === 'object' && country !== null && 'value' in country ? (country as any).value : country),
              postal_code: zip,
              state: city,
              line1: address,
              line2: company,
            },
            email: auth.currentUser?.email ?? "",
            phone: auth.currentUser?.phoneNumber ?? "",
          },
        },
      })
      .then((result) => {
        if (result.paymentMethod) {
          if (MODE_DEBUG) {
            console.log(result.paymentMethod);
          }
          const { id } = result.paymentMethod;
          return id;
        }
        if (result.error) {
          if (MODE_DEBUG) {
            console.log("[createPaymentMethod error]", result.error);
          }
          throw result.error;
        }
      })
      .then((paymentId) => {
        if (!plan?.id || !price.stripe_id || !paymentId) {
          if (MODE_DEBUG) {
            console.log(
              `create subscription parmeters missing! checkoutId:${plan?.id} stripe_id:${price.stripe_id} paymentID:${paymentId} is falsy`
            );
          }
          return;
        }

        return createSubscription(
          plan.id,
          price.stripe_id,
          paymentId,
          coupon?.coupon.stripe_id
        ).then((res) => {
          const { data } = res;
          if (MODE_DEBUG) {
            console.log(data);
          }
          if (data) {
            let object: any = {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              paymentMethodId: paymentId,
              priceId: price.stripe_id,
            };
            if (data.object === "invoice") {
              object.invoice = data;
            } else {
              object.subscription = data;
            }
            return object;
          }
        });
      })
      // Some payment methods require a customer to do additional
      // authentication with their financial institution.
      // Eg: 2FA for cards.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail. You will
      // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch(async (error) => {
        if (MODE_DEBUG) {
          console.error(error);
        }
        if (error?.message) {
          setPaymentError(error.message);
        }

        if (user?.uid && !authLoading) {
          const token = await user.getIdToken();
          mixpanelCheckout(
            user.uid,
            "failed",
            token,
            price.stripe_id,
            JSON.stringify(error)
          );
        }

        if (error.type === "card_error") {
          if (error.decline_code === "card_not_supported") {
            setPaymentError(
              "Your card is not supported. Please use a different card"
            );
          }
        }
        setPaymentFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    address,
    authLoading,
    city,
    company,
    country,
    coupon?.coupon?.stripe_id,
    elements,
    firstName,
    handlePaymentThatRequiresCustomerAction,
    handleRequiresPaymentMethod,
    lastName,
    onSubscriptionComplete,
    plan?.id,
    price?.stripe_id,
    stripe,
    user,
    zip,
  ]);

  const paymentHintText = useMemo(() => {
    if (price.recurring === "none") {
      return t("oneTime");
    } else if (price.recurring === "month" && price.iteration > 1) {
      return t("split", {
        price: `${price.amount / 100}${price.currency === "usd" ? "$" : "€"}`,
        count: price.iteration,
      });
    } else if (price.recurring === "month") {
      return t("perMonth", {
        price: `${price.amount / 100}${price.currency === "usd" ? "$" : "€"}`,
      });
    } else {
      return (
        t("perMonth", {
          price: `${(price.amount / 100 / 12).toFixed(2)}${
            price.currency === "usd" ? "$" : "€"
          }`,
        }) + " - facturation annuelle"
      );
    }
  }, [price?.amount, price.currency, price.iteration, price.recurring, t]);

  return (
    <div className="relative w-full xl:px-[10%] md:px-6 self-center gap-2 mt-10 overflow-y-auto overflow-x-hidden">
      {paymentFailed ? (
        <>
          <Lottie animationData={FailedLottie} loop={false} />
          <p className="text-red-500 self-center font-medium text-xs text-center -mt-4">
            {paymentError}
          </p>
          <button
            className="w-full h-[44px] green-gradient-button-bg mt-10 rounded-md"
            onClick={() => {
              setPaymentFailed(false);
              setPaymentError(t("somethingWrong"));
            }}
          >
            {t("tryAgain")}
          </button>
        </>
      ) : paymentSuccess ? (
        <>
          <Lottie animationData={SuccessLottie} loop={false} />
          <p className="text-green-500 self-center font-medium text-sm text-center -mt-4">
            {t("paymentSuccess")}
          </p>
          <a href="https://account.aryatrading.com/">
            <Col className="h-[44px] text-white items-center mt-10 justify-center green-gradient-button-bg rounded-md">
              {t("goToDashboard")}
            </Col>
          </a>
        </>
      ) : (
        <>
          <div className="w-full flex flex-col gap-2">
            <div>
              <p className="text-[#71BD22] font-semibold text-2xl md:text-3xl  ">
                &Eacute;tape 2
              </p>
              <h3 className="font-bold text-2xl md:text-3xl ">
                Paiement en ligne
              </h3>
            </div>
            <div
              className="w-full rounded-md p-4"
              style={{
                border: "2px solid #DEE2E6",
              }}
            >
              <p className="text-base font-semibold mb-2">{t("summary")}</p>
              <Row className="w-full">
                <p className="text-sm font-medium flex-1">
                  {price.product.name}
                </p>
                <p className="text-sm font-semibold">
                  {price?.amount / 100}
                  {price.currency === "usd" ? "$" : "€"}{" "}
                  {price.recurring !== "none" && ` / ${t(price.recurring)}`}
                </p>
              </Row>
              <p className="flex-1 font-normal text-xs mt-1 text-[#5C5F66]">
                {paymentHintText}
              </p>
              <div className="w-full h-[2px] bg-[#DEE2E6] my-4" />
              {plan && plan?.prices.length > 1 && (
                <>
                  <Row className="items-center gap-2">
                    <p className="text-base font-semibold flex-1">
                      {t("paymentPlan")}
                    </p>
                    <Row></Row>
                    {plan?.prices.map((item, index) => {
                      const isActive = item.price.id === price.id;
                      return (
                        <button
                          key={index}
                          className={clsx(
                            { "border-green-500 text-green-500": isActive },
                            "rounded-md border-[1px] border-gray-200 border-solid px-2 py-1 font-semibold"
                          )}
                          onClick={() => {
                            setPrice(item.price);
                            setCoupon(undefined);
                            const input: any =
                              document.getElementById("coupon");
                            input.value = "";
                          }}
                        >
                          <p>
                            {item.price.amount / 100}
                            {price.currency === "usd" ? "$" : "€"} /{" "}
                            {t(item.price.recurring)}
                          </p>
                        </button>
                      );
                    })}
                  </Row>
                  <div className="w-full h-[2px] bg-[#DEE2E6] my-4" />
                </>
              )}
              {plan?.has_coupon && (
                <Row
                  className={clsx(
                    {
                      "border-green-500": coupon?.valid,
                      "border-red-500 text-red-500": coupon?.valid === false,
                    },
                    "items-center h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                  )}
                >
                  <input
                    placeholder={t("coupon")}
                    className={clsx(
                      { "placeholder:text-red-500": coupon?.valid === false },
                      "w-full bg-[#fbfbfb] h-full text-sm"
                    )}
                    id="coupon"
                    onChange={() => setCoupon(undefined)}
                  />

                  <button
                    disabled={loadingCoupon}
                    className="text-xs font-semibold"
                    onClick={async () => {
                      const couponInp: any = document.getElementById("coupon");
                      if (coupon?.valid) {
                        setCoupon(undefined);
                        couponInp.value = "";
                      } else if (user) {
                        const token = await user.getIdToken();
                        const couponVal = couponInp.value;
                        if (couponVal.trim() == "" || !couponVal) {
                          setCoupon({
                            valid: false,
                            type: "",
                            message: t("enterCoupon"),
                          });
                        } else {
                          setLoadingCoupon(true);
                          applyCoupon({
                            coupon: couponVal,
                            userToken: token,
                            productId: price.product.id,
                            currency: price.currency,
                          })
                            .then((response) => {
                              setCoupon(response.data);
                            })
                            .finally(() => {
                              setLoadingCoupon(false);
                            });
                        }
                      }
                    }}
                  >
                    {loadingCoupon ? (
                      <Spinner />
                    ) : (
                      t(coupon?.valid ? "remove" : "apply")
                    )}
                  </button>
                </Row>
              )}
              {!coupon?.valid && (
                <p className="text-xs my-1 text-red-500">{coupon?.message}</p>
              )}

              {coupon?.valid && (
                <Row className="w-full items-center justify-center p-2">
                  <p className="text-sm font-medium flex-1">{t("subTotal")}</p>
                  <p className="text-sm font-semibold">
                    {price?.amount / 100}
                    {price.currency === "usd" ? "$" : "€"}
                  </p>
                </Row>
              )}
              {coupon?.valid && (
                <Row className="w-full items-center justify-center bg-green-100 p-2 rounded-md mb-2 border-[1px] border-green-600 border-solid">
                  <p className="text-sm font-medium flex-1 text-green-600">
                    {t("discount")}{" "}
                    {coupon?.valid &&
                      coupon.coupon.percent_off &&
                      "-" + (coupon.coupon.percent_off + "%")}
                  </p>
                  <p className="text-sm font-semibold text-green-600">
                    -
                    {coupon?.valid
                      ? coupon.coupon.percent_off
                        ? Math.round(
                            (price.amount / 100) *
                              (coupon.coupon.percent_off / 100)
                          )
                        : (coupon.coupon.amount_off ?? 0) / 100
                      : price?.amount / 100}
                    {price.currency === "usd" ? "$" : "€"}
                  </p>
                </Row>
              )}
              <Row
                className={clsx(
                  { "mt-4": !coupon?.valid },
                  "w-full items-center justify-center bg-gray-100 p-2 rounded-md"
                )}
              >
                <p className="text-sm font-bold flex-1">{t("total")}</p>
                <p className="text-sm font-bold">
                  {coupon?.valid
                    ? coupon.coupon.percent_off
                      ? Math.round(
                          (price.amount / 100) *
                            (1 - coupon.coupon.percent_off / 100)
                        )
                      : (price.amount - (coupon.coupon.amount_off ?? 0)) / 100
                    : price?.amount / 100}
                  {price.currency === "usd" ? "$" : "€"}{" "}
                  {price.recurring !== "none" && ` / ${t(price.recurring)}`}
                </p>
              </Row>
              <div className="w-full h-[2px] bg-[#DEE2E6] mt-4" />
              <button
                className="w-full"
                onClick={() => setShowBillingInfo((st) => !st)}
              >
                <Row className="items-center justify-between">
                  <p className="text-base font-semibold my-4">
                    {t("billingInfo")}
                  </p>

                  <span className={showBillingInfo ? "rotate-180" : ""}>
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.305299 1.06299C0.492826 0.875518 0.747134 0.770203 1.0123 0.770203C1.27746 0.770203 1.53177 0.875518 1.7193 1.06299L5.0123 4.35599L8.3053 1.06299C8.4939 0.880831 8.7465 0.780037 9.0087 0.782315C9.2709 0.784593 9.52171 0.889762 9.70712 1.07517C9.89252 1.26058 9.99769 1.51139 9.99997 1.77359C10.0023 2.03578 9.90146 2.28839 9.7193 2.47699L5.7193 6.47699C5.53177 6.66446 5.27746 6.76978 5.0123 6.76978C4.74713 6.76978 4.49283 6.66446 4.3053 6.47699L0.305299 2.47699C0.117828 2.28946 0.012512 2.03515 0.012512 1.76999C0.012512 1.50482 0.117828 1.25052 0.305299 1.06299Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </Row>
              </button>

              {showBillingInfo && (
                <Col className="items-center w-full gap-2">
                  <Row className="flex-col md:flex-row w-full gap-2">
                    <Col className="w-1/2">
                      <input
                        placeholder={t("billing.fname")}
                        className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {firstName?.trim() == "" && (
                        <p className="text-xs my-1 text-red-500">
                          {t("required", { item: "First name field is" })}
                        </p>
                      )}
                    </Col>
                    <Col className="w-1/2">
                      <input
                        placeholder={t("billing.lname")}
                        className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {lastName?.trim() == "" && (
                        <p className="text-xs my-1 text-red-500">
                          {t("required", { item: "Last name field is" })}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Col className="w-full">
                    <input
                      placeholder={t("billing.company")}
                      className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    {company?.trim() == "" && (
                      <p className="text-xs my-1 text-red-500">
                        {t("required", { item: "Company field is" })}
                      </p>
                    )}
                  </Col>
                  <Col className="w-full">
                    <input
                      placeholder={t("billing.address")}
                      className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {address?.trim() == "" && (
                      <p className="text-xs my-1 text-red-500">
                        {t("required", { item: "Address field is" })}
                      </p>
                    )}
                  </Col>
                  <Row className="flex-col md:flex-row w-full gap-2">
                    <Col className="w-1/2">
                      <input
                        placeholder={t("billing.zip")}
                        className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                      />
                      {zip?.trim() == "" && (
                        <p className="text-xs my-1 text-red-500">
                          {t("required", { item: "Zip code field is" })}
                        </p>
                      )}
                    </Col>
                    <Col className="w-1/2">
                      <input
                        placeholder={t("billing.city")}
                        className="text-sm w-full h-[44px] bg-[#fbfbfb] px-3 border-[1px] rounded-md border-solid border-[#ccc] font-medium"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      {city?.trim() == "" && (
                        <p className="text-xs my-1 text-red-500">
                          {t("required", { item: "City field is" })}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <CountrySelector
                    selectedCountry={country}
                    setSelectedCountry={setCountry}
                  />
                </Col>
              )}

              <p className="text-base font-semibold my-4">{t("method")}</p>
              <PaymentElement
                options={{
                  layout: {
                    type: "accordion",
                    defaultCollapsed: true,
                    radios: true,
                    spacedAccordionItems: true,
                  },
                }}
              />
            </div>
          </div>

          <button
            className="w-full h-[50px] rounded-md green-gradient-button-bg font-medium my-8 text-white"
            disabled={
              loading || !stripe || !elements || authLoading || !user?.uid
            }
            onClick={onPaymentStart}
          >
            {loading ? <Spinner /> : "Confirmer"}
          </button>
        </>
      )}
    </div>
  );
};

export default Form;
